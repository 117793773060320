import $ from 'jquery';

// Form validation
const name = document.getElementById('ask-name');
const email = document.getElementById('ask-email');
const form = document.getElementById('product-detail-form');

if (form) {
	form.addEventListener('submit', function (e) {
		e.preventDefault();
		if (validateName() && validateEmail()) {
			const form = document.getElementById('product-detail-form');
			$.ajax({
				url: 'email/enquiry',
				type: 'post',
				dataType: 'json',
				data: $('#product-detail-form').serialize(),
				success: function (data) {
					document.querySelector('.error_captcha').style.display = 'none';
					if(data.status) {
						form.className = 'form form--success';
					} else {
						document.querySelector('.error_captcha').innerHTML = data.validation_errors.captcha;
						document.querySelector('.error_captcha').style.display = 'block';
					}
				},
			});
		}
	});
}

function validateName() {
	if (checkIfEmpty(name)) return;
	if (!checkIfOnlyLetters(name)) return;
	return true;
}

function validateEmail() {
	if (checkIfEmpty(email)) return;
	if (!containsCharacters(email, 1)) return;
	return true;
}

function checkIfEmpty(input) {
	if (isEmpty(input.value.trim())) {
		setInvalid(input, `${input.name} must not be empty`);
		return true;
	} else {
		setValid(input);
		return false;
	}
}

function isEmpty(value) {
	if (value === '') return true;
	return false;
}

function setInvalid(input, message) {
	const formBlock = input.parentElement;
	const errorMessage = formBlock.querySelector('span');
	formBlock.className = 'form__block error';
	errorMessage.innerText = message;
}

function setValid(input) {
	input.nextElementSibling.innerHTML = '';
}

function checkIfOnlyLetters(input) {
	if (/^[a-zA-Z ]+$/.test(input.value)) {
		setValid(input);
		return true;
	} else {
		setInvalid(input, `${input.name} must contain only letters`);
		return false;
	}
}

function containsCharacters(field, code) {
	let regEx;
	switch (code) {
		case 1:
			// Email pattern
			regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return matchWithRegEx(regEx, field, 'Must be a valid email address');
		default:
			return false;
	}
}

function matchWithRegEx(regEx, field, message) {
	if (field.value.match(regEx)) {
		setValid(field);
		return true;
	} else {
		setInvalid(field, message);
		return false;
	}
}

if (name && email) {
	name.addEventListener('blur', () => validateName());
	email.addEventListener('blur', () => validateEmail());
}
