import $ from 'jquery';
import 'select2';

// Custom filter dropdown
$('#js-filter-sort').select2({
	minimumResultsForSearch: -1,
	placeholder: 'Sort by',
  allowClear: true
});
$('#js-filter-strategy').select2({
	minimumResultsForSearch: -1,
	placeholder: 'Filter strategy',
  allowClear: true
});
$('#js-filter-price').select2({
	minimumResultsForSearch: -1,
	placeholder: 'Filter price',
  allowClear: true
});
$('#js-filter-tags').select2({
	minimumResultsForSearch: -1,
	placeholder: 'Filter tags',
  allowClear: true
});

$('#js-filter-sort, #js-filter-strategy, #js-filter-price, #js-filter-tags').change(function(){
	filterFormSubmit();
})

var filterFormSubmit = function() {
	var filterSortBy = $('#js-filter-sort').val();
	var filterStrategy = $('#js-filter-strategy').val();
	var filterPrice = $('#js-filter-price').val();
	var filterTag = $('#js-filter-tags').val();
	var queryString = "sortby="+filterSortBy+"&strategy="+filterStrategy+"&price="+filterPrice+"&tag="+filterTag;
	var pathname = window.location.pathname;
	var url = BASE_URL+pathname+'?'+queryString;
	window.location.href = url;
}

// Filter mobile panel
const filterOpenTrigger = document.getElementById('js-filter-open-trigger');
const filterCloseTrigger = document.getElementById('js-filter-close-trigger');
const filterPanel = document.getElementById('js-filter__mobile');
const filterBody = document.body;

if (filterOpenTrigger) {
	filterOpenTrigger.addEventListener('click', () => {
		filterPanel.classList.add('is-active');
		filterBody.classList.add('is-active');
		filterOpenTrigger.style.display = 'none';
		filterCloseTrigger.style.display = 'block';
	});
}

if (filterCloseTrigger) {
	filterCloseTrigger.addEventListener('click', () => {
		filterPanel.classList.remove('is-active');
		filterBody.classList.remove('is-active');
		filterOpenTrigger.style.display = 'block';
		filterCloseTrigger.style.display = 'none';
	});
}
