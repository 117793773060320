import $ from 'jquery';

// Form validation
const email = document.getElementById('login-email');
const password = document.getElementById('login-password');
const form = document.getElementById('login-form');
const sendButton = document.getElementById('login-form-send');

if (form) {
	form.addEventListener('submit', function (e) {
		e.preventDefault();
		if (validateEmail() && validatePassword()) {
			const form = document.getElementById('login-form');
			$.ajax({
				url: 'server/email.php',
				type: 'post',
				dataType: 'json',
				data: $('#login-form').serialize(),
				success: function (data) {
					form.className = 'form form--success';
					sendButton.textContent = 'Logging in';
				},
			});
		}
	});
}

function validateEmail() {
	if (checkIfEmpty(email)) return;
	if (!containsCharacters(email, 1)) return;
	return true;
}

function validatePassword() {
	if (checkIfEmpty(password)) return;
	return true;
}

function checkIfEmpty(input) {
	if (isEmpty(input.value.trim())) {
		setInvalid(input, `${input.name} must not be empty`);
		return true;
	} else {
		setValid(input);
		return false;
	}
}

function isEmpty(value) {
	if (value === '') return true;
	return false;
}

function setInvalid(input, message) {
	const formBlock = input.parentElement;
	const errorMessage = formBlock.querySelector('span');
	formBlock.className = 'form__block error';
	errorMessage.innerText = message;
}

function setValid(input) {
	input.nextElementSibling.innerHTML = '';
}

function containsCharacters(field, code) {
	let regEx;
	switch (code) {
		case 1:
			// Email pattern
			regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return matchWithRegEx(regEx, field, 'Must be a valid email address');
		default:
			return false;
	}
}

function matchWithRegEx(regEx, field, message) {
	if (field.value.match(regEx)) {
		setValid(field);
		return true;
	} else {
		setInvalid(field, message);
		return false;
	}
}

if (email && password) {
	email.addEventListener('blur', () => validateEmail());
	password.addEventListener('blur', () => validatePassword());
}
