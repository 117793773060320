import './courses.js';
import './product-detail.js';
import './property-detail.js';
import './property-listing.js';
//import './property-listing-map.js';
import './property-listing-form.js';
import './sign-up.js';
import './login.js';
import './forgot-password.js';
import './investment-platform-application.js';
// import './power-team-form.js';
import './your-dashboard.js';
import Swiper from 'swiper/bundle';
import videojs from 'video.js';
import $ from 'jquery';
import 'remodal';

import 'swiper/css/bundle';
import './scss/main.scss';

// Global variables
const openMenuBtn = document.querySelectorAll('.js-menu-open');
const closeMenuBtn = document.querySelectorAll('.js-menu-close');
const tabs = document.querySelectorAll('.btn--tab');
const tabsContainer = document.querySelector('.js-tab');
const tabsContent = document.querySelectorAll('.tab__content');
const accordionBtns = document.querySelectorAll('.js-btn--accordion');

// check element in viewport
// credit: https://gomakethings.com/how-to-test-if-an-element-is-in-the-viewport-with-vanilla-javascript/
var isInViewport = function (elem) {
	var distance = elem.getBoundingClientRect();
	return (
		distance.top >= 0 &&
		distance.left >= 0 &&
		distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		distance.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
};

var elemInFocus = document.querySelectorAll('.viewPortTracking');
var elemMoveIn = function() {
	for(var i = 0; i < elemInFocus.length; i++) {
		var elemInFocusItem = elemInFocus[i];
		var offset = elemInFocusItem.dataset.offset;
		if(typeof offset == 'undefined') {
			offset = 0;
		} else {
			offset = parseInt(offset);
		}

		if(isInViewport(elemInFocusItem, offset)) {
			elemInFocusItem.classList.add('in');
		} else {
			elemInFocusItem.classList.remove('in');
		}
	}
}
window.addEventListener('scroll', function(event) {
	elemMoveIn();
}, false);
setTimeout(function() {
	elemMoveIn();
}, 500)

/**
 * figure count up animation
 *
 */

var figureItemCountUp = function() {
	// Counter
	const counters = document.querySelectorAll('.figuresItem.viewPortTracking.in > .value');
	const speed = 200;

	counters.forEach(counter => {
		const animate = () => {
			const value = +counter.getAttribute('number');
			const data = +counter.innerText;

			const time = value / speed;
			if(data < value) {
				counter.innerText = Math.ceil(data + time);
				setTimeout(animate, speed); // this control the speed of the counting
			} else {
				counter.innerText = value;
			}
		}
		animate();
	});
}

if($('.figuresItem')
	.length > 0) {
	$(window)
		.scroll(function() {
			// on scroll
			figureItemCountUp();
		});

	// on load
	figureItemCountUp();
}

// Tabs
if(tabsContainer) {
	tabsContainer.addEventListener('click', function(e) {
		const clicked = e.target.closest('.btn--tab');

		if(!clicked) {
			return;
		}

		tabs.forEach(t => t.classList.remove('is-active'));
		clicked.classList.add('is-active');

		tabsContent.forEach(c => c.classList.remove('is-active'));
		document.querySelector(`.tab__content--${clicked.dataset.tab}`)
			.classList.add('is-active');
	});
}

// Video modal
if(document.getElementById('intro-video')) {
	const introPlayer = videojs('intro-video', {
		autoplay: 'muted',
		fluid: true,
	});

	$(document)
		.on('opened', '#play-hero-video', function() {
			introPlayer.play('muted');
			introPlayer.muted(false);
		});

	$(document)
		.on('closing', '#play-hero-video', function(e) {
			introPlayer.pause();
			introPlayer.play('pause');
			introPlayer.muted(true);
		});
}

if(document.getElementById('course-video')) {
	const coursePlayer = videojs('course-video', {
		autoplay: 'muted',
		fluid: true,
	});

	$(document)
		.on('opened', '#play-course-video', function() {
			coursePlayer.play('muted');
			coursePlayer.muted(false);
		});

	$(document)
		.on('closing', '#play-course-video', function(e) {
			coursePlayer.pause();
			coursePlayer.play('pause');
			coursePlayer.muted(true);
		});
}

if(document.getElementById('investment-video')) {
	const investmentPlayer = videojs('investment-video', {
		autoplay: 'muted',
		fluid: true,
	});

	$(document)
		.on('opened', '#play-investment-video', function() {
			investmentPlayer.play('muted');
			investmentPlayer.muted(false);
		});

	$(document)
		.on('closing', '#play-investment-video', function(e) {
			investmentPlayer.pause();
			investmentPlayer.play('pause');
			investmentPlayer.muted(true);
		});
}

if(document.getElementById('verified-hero-video')) {
	const verifiedHeroPlayer = videojs('verified-hero-video', {
		autoplay: 'muted',
		fluid: true,
	});

	$(document)
		.on('opened', '#play-verified-hero-video', function() {
			verifiedHeroPlayer.play('muted');
			verifiedHeroPlayer.muted(false);
		});

	$(document)
		.on('closing', '#play-verified-hero-video', function(e) {
			verifiedHeroPlayer.pause();
			verifiedHeroPlayer.play('pause');
			verifiedHeroPlayer.muted(true);
		});
}

// Navigation
openMenuBtn.forEach(el => el.addEventListener('click', () => document.getElementById('nav')
	.classList.add('is-open')));
closeMenuBtn.forEach(el => el.addEventListener('click', () => document.getElementById('nav')
	.classList.remove('is-open')));

// Step images
$('.step')
	.hover(
		function() {
			$('#js-switch')
				.attr('src', $(this)
					.data('src'))
				.stop(true, true)
				.hide()
				.fadeIn(1500);
		},
		function() {
			$('#js-switch')
				.attr('src', $(this)
					.data('src'));
		}
	);

$('.js-toggle-mobile-accordion')
	.click(function() {
		var $parent = $(this)
			.closest('.bundle');
		$parent.toggleClass('is-active');
	});

// FAQ Accordion
accordionBtns.forEach(accordion => {
	accordion.onclick = function() {
		this.classList.toggle('is-active');

		let content = this.nextElementSibling;

		if(content.style.maxHeight) {
			content.style.maxHeight = null;
		} else {
			content.style.maxHeight = content.scrollHeight + 'px';
		}
	};
});

// Swiper Courses
const swiperCourses = new Swiper('.swiper--courses', {
	autoHeight: true,
	autoplay: {
		delay: 5000,
	},
	loop: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

// Swiper Platform
const swiperPlatform = new Swiper('.swiper--platform', {
	autoHeight: true,
	autoplay: {
		delay: 5000,
	},
	loop: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

// Change header background on scroll
if(document.querySelector('.l-header--transparent')) {
	let scrollPosition = window.scrollY;
	const headerTransparent = document.querySelector('.l-header--transparent');
	const headerHeight = headerTransparent.offsetHeight;

	const addHeaderClassOnScroll = () => headerTransparent.classList.add('is-active');
	const removeHeaderClassOnScroll = () => headerTransparent.classList.remove('is-active');

	window.addEventListener('scroll', function() {
		scrollPosition = window.scrollY;

		if(scrollPosition >= headerHeight) {
			addHeaderClassOnScroll();
		} else {
			removeHeaderClassOnScroll();
		}
	});
}

// Responsive property selection slider
const breakpoint = window.matchMedia('(min-width: 991px)');
let propertySelectionSwiper;

const propertySelectionBreakpointChecker = function() {
	if(breakpoint.matches === true) {
		if(propertySelectionSwiper !== undefined) propertySelectionSwiper.destroy(true, true);
		return;
	} else if(breakpoint.matches === false) {
		return enablepropertySelectionSwiper();
	}
};

const enablepropertySelectionSwiper = function() {
	propertySelectionSwiper = new Swiper('.js-swiper-properties', {
		loop: true,
		slidesPerView: 1,
		spaceBetween: 20,
		pagination: {
			el: '.swiper-pagination--properties',
			clickable: true,
		},
	});
};

breakpoint.addListener(propertySelectionBreakpointChecker);
propertySelectionBreakpointChecker();
