import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

// Swiper Property Details
const swiperPropertyThumb = new Swiper('.swiper--property-thumb', {
	spaceBetween: 20,
	slidesPerView: 3,
	grabCursor: true,
	watchSlidesProgress: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

const swiperPropertyLarge = new Swiper('.swiper--property-lg', {
	thumbs: {
		swiper: swiperPropertyThumb,
	},
});

// Swiper Floorplans
const swiperFloorplans = new Swiper('.swiper--floorplans', {
	autoHeight: true,
	loop: true,
	grabCursor: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

// Property details map
if (document.querySelectorAll('.map-property-detail')) {
  var mapElems = document.querySelectorAll('.map-property-detail');
  for(var i=0;i<mapElems.length;i++) {
    google.maps.event.addDomListener(window, 'load', init(mapElems[i]));
  }

	function init(mapElem) {
    var lat = parseFloat(mapElem.dataset.lat);
    var lng = parseFloat(mapElem.dataset.lng);
		const mapOptions = {
			zoom: 8,
			center: new google.maps.LatLng(lat, lng),
			styles: [ { 'featureType': 'administrative', 'elementType': 'labels.text.fill', 'stylers': [ { 'color': '#222525', }, ], }, { 'featureType': 'administrative', 'elementType': 'labels.text.stroke', 'stylers': [ { 'color': '#f8f5f2', }, ], }, { 'featureType': 'landscape', 'elementType': 'all', 'stylers': [ { 'color': '#f8f5f2', }, ], }, { 'featureType': 'poi', 'elementType': 'all', 'stylers': [ { 'visibility': 'off', }, ], }, { 'featureType': 'road', 'elementType': 'all', 'stylers': [ { 'saturation': -100, }, { 'lightness': 45, }, ], }, { 'featureType': 'road.highway', 'elementType': 'all', 'stylers': [ { 'visibility': 'on', }, ], }, { 'featureType': 'road.arterial', 'elementType': 'labels.icon', 'stylers': [ { 'visibility': 'off', }, ], }, { 'featureType': 'transit', 'elementType': 'all', 'stylers': [ { 'visibility': 'off', }, ], }, { 'featureType': 'water', 'elementType': 'all', 'stylers': [ { 'color': '#078080', }, { 'visibility': 'on', }, ], }, ], };
		const map = new google.maps.Map(mapElem, mapOptions);

  new google.maps.Marker({
    position: {lat: lat, lng: lng},
    map,
  });
	}
}
