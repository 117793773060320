// Courses accordion
const acc = document.getElementsByClassName('js-accordion--courses');
let i;

for (i = 0; i < acc.length; i++) {
	acc[i].addEventListener('click', function () {
		this.classList.toggle('is-active');
		const panel = this.nextElementSibling;
		if (panel.style.display === 'flex') {
			panel.style.display = 'none';
		} else {
			panel.style.display = 'flex';
		}
	});
}

// Course mobile panel
const courseOpenTrigger = document.getElementById('js-course-open-trigger');
const courseCloseTrigger = document.getElementById('js-course-close-trigger');
const coursePanel = document.getElementById('js-course-panel');
const courseBody = document.body;

if (courseOpenTrigger) {
	courseOpenTrigger.addEventListener('click', () => {
		coursePanel.classList.add('is-active');
		courseBody.classList.add('is-active');
		courseOpenTrigger.style.display = 'none';
		courseCloseTrigger.style.display = 'block';
	});
}

if (courseCloseTrigger) {
	courseCloseTrigger.addEventListener('click', () => {
		coursePanel.classList.remove('is-active');
		courseBody.classList.remove('is-active');
		courseOpenTrigger.style.display = 'block';
		courseCloseTrigger.style.display = 'none';
	});
}
