// Your dashboard map
if (document.getElementById('map-dashboard')) {
	google.maps.event.addDomListener(window, 'load', init);

	function init() {
		const mapOptions = {
			zoom: 5,
			center: new google.maps.LatLng(54.605309, -3.3214603),
			styles: [
				{
					'featureType': 'administrative',
					'elementType': 'labels.text.fill',
					'stylers': [
						{
							'color': '#222525',
						},
					],
				},
				{
					'featureType': 'administrative',
					'elementType': 'labels.text.stroke',
					'stylers': [
						{
							'color': '#f8f5f2',
						},
					],
				},
				{
					'featureType': 'landscape',
					'elementType': 'all',
					'stylers': [
						{
							'color': '#f8f5f2',
						},
					],
				},
				{
					'featureType': 'poi',
					'elementType': 'all',
					'stylers': [
						{
							'visibility': 'off',
						},
					],
				},
				{
					'featureType': 'road',
					'elementType': 'all',
					'stylers': [
						{
							'saturation': -100,
						},
						{
							'lightness': 45,
						},
					],
				},
				{
					'featureType': 'road.highway',
					'elementType': 'all',
					'stylers': [
						{
							'visibility': 'on',
						},
					],
				},
				{
					'featureType': 'road.arterial',
					'elementType': 'labels.icon',
					'stylers': [
						{
							'visibility': 'off',
						},
					],
				},
				{
					'featureType': 'transit',
					'elementType': 'all',
					'stylers': [
						{
							'visibility': 'off',
						},
					],
				},
				{
					'featureType': 'water',
					'elementType': 'all',
					'stylers': [
						{
							'color': '#078080',
						},
						{
							'visibility': 'on',
						},
					],
				},
			],
		};
		const mapElement = document.getElementById('map-dashboard');
		const map = new google.maps.Map(mapElement, mapOptions);
	}
}
