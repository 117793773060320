import $ from 'jquery';

// Form validation
const firstName = document.getElementById('investment-platform-application-first-name');
const lastName = document.getElementById('investment-platform-application-last-name');
const email = document.getElementById('investment-platform-application-email');
const telephone = document.getElementById('investment-platform-application-telephone');
const goal = document.getElementById('investment-platform-application-goal');
const challenge = document.getElementById('investment-platform-application-challenge');
const value = document.getElementById('investment-platform-application-value');
const motivation = document.getElementById('investment-platform-application-motivation');
const form = document.getElementById('investment-platform-application');
const sendButton = document.getElementById('investment-platform-application-send');

if (form) {
	form.addEventListener('submit', function (e) {
		e.preventDefault();
		if (validateFirstName() && validateLastName() && validateEmail() && validateTel() && validateGoal() && validateChallenge() && validateValue() && validateMotivation()) {
			const name = firstName.value;
			const container = document.getElementById('investment-platform-application');
			const form = document.getElementById('investment-platform-application');
			$.ajax({
				url: BASE_URL+'/email/application',
				type: 'post',
				dataType: 'json',
				data: $('#investment-platform-application').serialize(),
				success: function (data) {
					form.className = 'form form--success';
					sendButton.textContent = 'Sending';
					sendButton.classList.add('btn--loading');
					const panel = document.createElement('div');
					panel.className = 'form__panel';
					const innerPanel = document.createElement('div');
					innerPanel.className = 'form__message';
					panel.appendChild(innerPanel);
					//const text = document.createElement('p');
					//text.appendChild(document.createTextNode(`Thank you for your message, ${name}. We'll get back to you soon.`));
					sendButton.textContent = 'Sent!';
					sendButton.classList.remove('btn--loading');
					innerPanel.appendChild(text);
					container.appendChild(panel);
				},
			});
		}
	});
}

function validateFirstName() {
	if (checkIfEmpty(firstName)) return;
	if (!checkIfOnlyLetters(firstName)) return;
	return true;
}

function validateLastName() {
	if (checkIfEmpty(lastName)) return;
	if (!checkIfOnlyLetters(lastName)) return;
	return true;
}

function validateEmail() {
	if (checkIfEmpty(email)) return;
	if (!containsCharacters(email, 1)) return;
	return true;
}

function validateTel() {
	if (checkIfEmpty(telephone)) return;
	if (!containsNumbers(telephone)) return;
	return true;
}

function validateGoal() {
	if (checkIfEmpty(goal)) return;
	return true;
}

function validateChallenge() {
	if (checkIfEmpty(challenge)) return;
	return true;
}

function validateValue() {
	if (checkIfEmpty(value)) return;
	return true;
}

function validateMotivation() {
	if (checkIfEmpty(motivation)) return;
	return true;
}

function containsNumbers(input) {
	const phoneNum = /[2-9]{1}\d{2}/;
	if (input.value.match(phoneNum)) {
		return true;
	} else {
		setInvalid(input, `${input.name} must contain only numbers`);
		return false;
	}
}

function checkIfEmpty(input) {
	if (isEmpty(input.value.trim())) {
		setInvalid(input, `${input.name} must not be empty`);
		return true;
	} else {
		setValid(input);
		return false;
	}
}

function isEmpty(value) {
	if (value === '') return true;
	return false;
}

function setInvalid(input, message) {
	const formBlock = input.parentElement;
	const errorMessage = formBlock.querySelector('span');
	formBlock.className = 'form__block error';
	errorMessage.innerText = message;
}

function setValid(input) {
	input.nextElementSibling.innerHTML = '';
}

function checkIfOnlyLetters(input) {
	if (/^[a-zA-Z ]+$/.test(input.value)) {
		setValid(input);
		return true;
	} else {
		setInvalid(input, `${input.name} must contain only letters`);
		return false;
	}
}

function containsCharacters(field, code) {
	let regEx;
	switch (code) {
		case 1:
			// Email pattern
			regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return matchWithRegEx(regEx, field, 'Must be a valid email address');
		default:
			return false;
	}
}

function matchWithRegEx(regEx, field, message) {
	if (field.value.match(regEx)) {
		setValid(field);
		return true;
	} else {
		setInvalid(field, message);
		return false;
	}
}

if (firstName && email && lastName && telephone && goal && challenge && value && motivation) {
	firstName.addEventListener('blur', () => validateFirstName());
	lastName.addEventListener('blur', () => validateLastName());
	email.addEventListener('blur', () => validateEmail());
	telephone.addEventListener('blur', () => validateTel());
	goal.addEventListener('blur', () => validateGoal());
	challenge.addEventListener('blur', () => validateChallenge());
	value.addEventListener('blur', () => validateValue());
	motivation.addEventListener('blur', () => validateMotivation());
}
